var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "border rounded-top border-primary pt-4 px-2",
      staticStyle: { height: "100%" },
    },
    [
      _c("div", { staticClass: "mb-2" }, [
        _c("img", {
          staticClass: "w-100 imgHeight",
          attrs: {
            src:
              _vm.projet.projectImageXid ||
              "https://pocketsolutionsltd.files.wordpress.com/2012/10/grey-landscape-background2.png",
            alt: "",
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "text-left cardTextBody w-90 mt-4 pl-3" },
        [
          _c("h5", [
            _c("b", [
              _vm._v(
                _vm._s(_vm.FormMSG(50, "Project: ")) + _vm._s(_vm.projet.name)
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "fw-500 fs-10 p-0 btn-transparent",
              staticStyle: { color: "#225cbd" },
              on: {
                click: function ($event) {
                  _vm.tagVisible = !_vm.tagVisible
                },
              },
            },
            [
              _vm.tagVisible
                ? _c("span", [_vm._v(_vm._s(_vm.FormMSG(25, "Hide detail")))])
                : _vm._e(),
              !_vm.tagVisible
                ? _c("span", [_vm._v(_vm._s(_vm.FormMSG(26, "Show detail")))])
                : _vm._e(),
              _vm.tagVisible
                ? _c("chevron-up", { attrs: { size: 16, fill: "#225CBD" } })
                : _vm._e(),
              !_vm.tagVisible
                ? _c("chevron-down", { attrs: { size: 16, fill: "#225CBD" } })
                : _vm._e(),
            ],
            1
          ),
          _c("b-collapse", { attrs: { visible: _vm.tagVisible } }, [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.projet.description) },
            }),
          ]),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("h5", { staticClass: "Tcolor" }, [
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.FormMSG(51, "Method: ")) +
                      _vm._s(_vm.projet.offsetMethodName)
                  ),
                ]),
              ]),
              _c(
                "button",
                {
                  staticClass: "fw-500 fs-10 p-0 btn-transparent",
                  staticStyle: { color: "#225cbd" },
                  on: {
                    click: function ($event) {
                      _vm.tagVisible2 = !_vm.tagVisible2
                    },
                  },
                },
                [
                  _vm.tagVisible2
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.FormMSG(25, "Hide detail"))),
                      ])
                    : _vm._e(),
                  !_vm.tagVisible2
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.FormMSG(26, "Show detail"))),
                      ])
                    : _vm._e(),
                  _vm.tagVisible2
                    ? _c("chevron-up", { attrs: { size: 16, fill: "#225CBD" } })
                    : _vm._e(),
                  !_vm.tagVisible2
                    ? _c("chevron-down", {
                        attrs: { size: 16, fill: "#225CBD" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("b-collapse", { attrs: { visible: _vm.tagVisible2 } }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.projet.offsetMethodDescription),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "text-right pr-3" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }