var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "h1",
          {
            class: [
              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
              { "is-pwa": _vm.$isPwa() },
            ],
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.FormMSG(7, "Carbon removal detail")) +
                "\n\t\t"
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "card px-3 py-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h4", [
              _c("b", [
                _c("i", {
                  staticClass: "fa fa-arrow-left",
                  attrs: { role: "button", "aria-hidden": "true" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.back()
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.portfolioDetail.name)),
              ]),
            ]),
          ]),
          _c("b-col", { staticClass: "mt-3", attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                class: !_vm.$screen.md
                  ? "justify-content-around mx-2"
                  : "d-flex justify-content-around mx-2",
              },
              _vm._l(_vm.portfolioDetail.projects, function (projet) {
                return _c(
                  "b-col",
                  {
                    key: projet.id,
                    class: !_vm.$screen.md ? "mb-3" : "",
                    attrs: { cols: !_vm.$screen.md ? 12 : 3 },
                  },
                  [_c("card-body", { attrs: { projet: projet } })],
                  1
                )
              }),
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }