<template lang="">
	<div class="border rounded-top border-primary pt-4 px-2" style="height: 100%">
		<div class="mb-2">
			<img
				:src="projet.projectImageXid || 'https://pocketsolutionsltd.files.wordpress.com/2012/10/grey-landscape-background2.png'"
				class="w-100 imgHeight"
				alt=""
			/>
		</div>
		<div class="text-left cardTextBody w-90 mt-4 pl-3">
			<h5>
				<b>{{ FormMSG(50, 'Project: ') }}{{ projet.name }}</b>
			</h5>
			<button class="fw-500 fs-10 p-0 btn-transparent" @click="tagVisible = !tagVisible" style="color: #225cbd">
				<span v-if="tagVisible">{{ FormMSG(25, 'Hide detail') }}</span>
				<span v-if="!tagVisible">{{ FormMSG(26, 'Show detail') }}</span>
				<chevron-up v-if="tagVisible" :size="16" fill="#225CBD" />
				<chevron-down v-if="!tagVisible" :size="16" fill="#225CBD" />
			</button>
			<b-collapse :visible="tagVisible">
				<p v-html="projet.description"></p>
			</b-collapse>
			<div class="mt-3">
				<h5 class="Tcolor">
					<b>{{ FormMSG(51, 'Method: ') }}{{ projet.offsetMethodName }}</b>
				</h5>
				<button class="fw-500 fs-10 p-0 btn-transparent" @click="tagVisible2 = !tagVisible2" style="color: #225cbd">
					<span v-if="tagVisible2">{{ FormMSG(25, 'Hide detail') }}</span>
					<span v-if="!tagVisible2">{{ FormMSG(26, 'Show detail') }}</span>
					<chevron-up v-if="tagVisible2" :size="16" fill="#225CBD" />
					<chevron-down v-if="!tagVisible2" :size="16" fill="#225CBD" />
				</button>
				<b-collapse :visible="tagVisible2">
					<p v-html="projet.offsetMethodDescription"></p>
				</b-collapse>
			</div>
		</div>
		<div class="text-right pr-3"></div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { ChevronUp, ChevronDown } from 'lucide-vue';

export default {
	name: 'CardBody',
	mixins: [languageMessages],
	components: {
		ChevronUp,
		ChevronDown
	},
	props: {
		projet: { type: Object, default: {} }
	},
	data() {
		return {
			tagVisible: false,
			tagVisible2: false
		};
	}
};
</script>
